.App {
  min-height: 80vh;
}
.slider{
  width: 200px;
}
.adsButton {
  color: #fff;
  text-decoration: none;
  background-color: #1976d2;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
